<template>
    <v-container
    class="section">
    <v-card>
        <v-card-title>{{ $t(title) }}</v-card-title>
        <v-card-text v-html="$t(text)"></v-card-text>
        
        <BaseImage
        :imageName="imageName"
        :captionText="captionText"
        :altText="altText"
        ></BaseImage>
        
    </v-card>
    </v-container>
</template>


<script>
import BaseImage from '@/components/base/BaseImage';
export default {
    components: {
        BaseImage,
    },
    props: ['title', 'text', 'imageName', 'captionText', 'altText'],
}
</script>