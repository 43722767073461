<template>
    <v-container>
        <base-section-heading
        title="about.processing.title">
        </base-section-heading>

        <SectionWithImage
        v-for="section in sectionsWithImage"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :imageName="section.imageName"
        :captionText="section.captionText"
        :altText="section.altText"
        ></SectionWithImage>

        <SectionWithAlert
        v-for="section in sectionsWithAlert"
        :key="section.key"
        :id="section.id"
        :title="section.title"
        :text="section.text"
        :introSeeAlso="section.introSeeAlso"
        :seeAlso="section.seeAlso"
        :link="section.link"
        :type="section.type"
        ></SectionWithAlert>

    </v-container>
</template>

<script>
import SectionWithImage from '@/components/docs/SectionWithImage'
import SectionWithAlert from '@/components/docs/SectionWithAlert'
export default {
    components: {
      SectionWithImage,
      SectionWithAlert,
    },
    data() {
        return {
            sectionsWithImage: [
                { 
                    key: 1,
                    id: 'structure-section',    
                    title: 'about.processing.section-1.title',
                    text: 'about.processing.section-1.text',
                    imageName: 'kwickk-1.gif',
                    captionText: 'about.processing.section-1.captionText',
                    altText: 'about.processing.section-1.altText'
                },
                {
                    key: 2,
                    id: 'markup-section',
                    title: 'about.processing.section-2.title',
                    text: 'about.processing.section-2.text',
                    imageName: 'kwickk-2.gif',
                    captionText: 'about.processing.section-2.captionText',
                    altText: 'about.processing.section-2.altText'
                },
                {
                    key: 3,
                    id: 'addmetadata-section',
                    title: 'about.processing.section-3.title',
                    text: 'about.processing.section-3.text',
                    imageName: 'kwickk-3.gif',
                    captionText: 'about.processing.section-3.captionText',
                    altText: 'about.processing.section-3.altText'
                },
            ],
            sectionsWithAlert: [
                {
                    key: 4,
                    id: 'scripts-section',
                    title: 'about.processing.section-4.title',
                    text: 'about.processing.section-4.text',
                    introSeeAlso: 'about.processing.section-4.introSeeAlso',
                    seeAlso: 'about.processing.section-4.seeAlso',
                    link: 'about.processing.section-4.link',
                    type:'external'
                }
            ]
        }
    }
}
</script>

<style scoped>
a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
div >>> a:hover {
    background-color: lightyellow ;
    text-decoration: none
}
</style>
